import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { firebaseConfig, secondFirebaseConfig } from "./config";

const app = initializeApp(firebaseConfig, "fisrt");
const secondApp = initializeApp(secondFirebaseConfig, "second");
const auth = getAuth(app);
const db = getFirestore(app);
const secondDb = getFirestore(secondApp);

const logout = () => {
  signOut(auth);
};

export { auth, db, logout, secondDb };
