import { Box } from "@mui/material";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logo from "../assets/Logo.png";
import { StyledLoadingButton } from "../components";
import { useSelector } from "react-redux";
import { getContent } from "../store";
import { signInWithEmailPassword } from "../firebase";
interface IFormInput {
  email: string;
  password: string;
}
function Login() {
  // useNavigate
  const navigate = useNavigate();

  const content = useSelector(getContent);
  // useState
  const [loading, setLoading] = useState<boolean>(false);

  // useForm
  const { register, handleSubmit } = useForm<IFormInput>();

  // Other functions
  const onSubmit: SubmitHandler<IFormInput> = async ({ email, password }) => {
    setLoading(true);

    await signInWithEmailPassword(email, password, content);

    setLoading(false);

    navigate("/");
  };

  return (
    <div className="text-white w-full h-full bg-ninth flex flex-col justify-center items-center">
      <div className="flex flex-col justify-between items-center p-10 rounded-2xl w-[90%] lg:w-[70%] xl:w-[50%] xxl:w-[40%]">
        <Box
          component={"img"}
          alt="logo"
          src={logo}
          sx={{ objectFit: "contain" }}
        ></Box>
        <h1 className="py-10 text-3xl font-montserrat800">
          - Admin Dashboard -
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-rows-3 gap-10 w-full"
        >
          <input
            {...register("email", { required: true, maxLength: 100 })}
            type="text"
            placeholder="User Name"
            className="bg-transparent border border-twentyTwo px-5 py-4 rounded-lg focus:border-fourth w-full"
          />
          <input
            {...register("password", { required: true, maxLength: 50 })}
            type="password"
            placeholder="Password"
            className="bg-transparent border border-twentyTwo px-5 rounded-lg focus:border-fourth"
          />
          <StyledLoadingButton
            loading={loading}
            type="submit"
            className="p-5 border border-white rounded-lg mx-20 hover:text-fourth hover:border-fourth bg-seven"
          >
            Login
          </StyledLoadingButton>
        </form>
      </div>
    </div>
  );
}

export default Login;
