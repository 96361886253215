import { styled } from "@mui/system";

export const StyledContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "40px",
  "& > img": {
    width: "100px",
    height: "100px",
  },
}));
