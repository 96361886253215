import { GridColDef } from "@mui/x-data-grid";

//import component
import MoreButton from "../../components/Tables/MoreButton";

// import custom hooks

// Const
import { LAUNCHPAD_STATUS } from "../../const";

// import icon libarary
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Close from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import { NavigateFunction } from "react-router-dom";

const checkStatus = (status: string) => {
  switch (status) {
    case LAUNCHPAD_STATUS.Pending:
      return "text-twentyOne";
    case LAUNCHPAD_STATUS.Approved:
      return "text-twentyThree";
    case LAUNCHPAD_STATUS.Cancelled:
      return "text-red-700";
  }
  return "";
};

export const columns = (
  handleCancel: any,
  handleApproved: any
): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.id}</span>
        </div>
      ),
    },
    {
      field: "closingDate",
      headerName: "Closing date",
      sortable: false,
      minWidth: 150,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX  text-fourth">
          <span>
            {moment(new Date(rowData.row.closingDate as any)).format(
              "DD/MM/YYYY HH:mm:ss"
            )}
          </span>
        </div>
      ),
    },

    {
      field: "price",
      headerName: "Price",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.price} TAL</span>
        </div>
      ),
    },

    {
      field: "owner",
      headerName: "Owner",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header",
      cellClassName: "cell",
      disableColumnMenu: true,
      renderCell: (rowData: any) => (
        <div className="scrollTableX">
          <span>{rowData.row.owner}</span>
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 200,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell !overflow-visible",
      disableColumnMenu: true,
      renderCell: (rowData: any) => {
        return (
          <div
            className={`flex justify-between w-full  ${checkStatus(
              rowData.row.status
            )}`}
          >
            <span className="max-w-[90%] scrollTableX ">
              {rowData.row.status}
            </span>
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      minWidth: 50,
      flex: 2,
      headerClassName: "header ",
      cellClassName: "cell !overflow-visible",
      disableColumnMenu: true,
      renderCell: (rowData: any) => {
        return (
          <div>
            <MoreButton
              props={{
                buttons: [
                  {
                    icon: <RemoveRedEyeIcon />,
                    quote: "View",
                    popup: false,
                    handleClick: (navigate: NavigateFunction) => {
                      navigate(`/laundpads/${rowData.row.id}`);
                    },
                  },
                  {
                    ...(rowData.row.status === LAUNCHPAD_STATUS?.Pending &&
                      ({
                        icon: <CheckCircleOutlineIcon />,
                        quote: "Approve",
                        popup: false,
                        handleClick: () => handleApproved(rowData.row.id),
                      } as any)),
                  },
                  {
                    ...(rowData.row.status === LAUNCHPAD_STATUS?.Pending &&
                      ({
                        icon: <Close />,
                        quote: "Reject",
                        popup: false,
                        handleClick: () => handleCancel(rowData.row.id),
                      } as any)),
                  },
                ],
              }}
            />
          </div>
        );
      },
    },
  ];
};
