import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { Spinner } from "../components";
import { slugs } from "../const/slugs";
import { Launchpad } from "../pages/LaunchpadList";
import { LaundpadDetail } from "../pages/LaundpadDetail";
import { NFTDetail } from "../pages/NFTDetail";
import { NFTs } from "../pages/NFTs";

import { RewardNFT } from "../pages/RewardNFT";
import { TransactionNft } from "../pages/TransactionNft";
// Pages
const Dashboard = lazy(() => import("../pages/Dashboard"));
const TransactionDetails = lazy(() => import("../pages/TransactionDetails"));
const Transaction = lazy(() => import("../pages/Transaction"));
const UserDetails = lazy(() => import("../pages/UserDetails"));
const UserList = lazy(() => import("../pages/UserList"));
const Locked = lazy(() => import("../pages/Locked"));
const NFTViews = lazy(() => import("../pages/NftViews"));
const CreateNftManual = lazy(() => import("../pages/CreateNftManual"));

export const routes = [
  {
    path: slugs.dashboard,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <Dashboard />
        </Suspense>
      );
    },
  },
  {
    path: slugs.transactions,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <Transaction />
        </Suspense>
      );
    },
  },
  {
    path: slugs.users,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <UserList />
        </Suspense>
      );
    },
  },
  {
    path: slugs.locked,
    main: function DashboardPage() {
      return (
        <Suspense fallback={"Loading..."}>
          <Locked />
        </Suspense>
      );
    },
  },
  {
    path: slugs.transaction,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <TransactionDetails />
        </Suspense>
      );
    },
  },
  {
    path: slugs.user,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <UserDetails />
        </Suspense>
      );
    },
  },

  {
    path: slugs.laundpads,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <Launchpad />
        </Suspense>
      );
    },
  },
  {
    path: slugs.laundpad,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <LaundpadDetail />
        </Suspense>
      );
    },
  },
  {
    path: slugs.nfts,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <NFTs />
        </Suspense>
      );
    },
  },
  {
    path: slugs.nftViews,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <NFTViews />
        </Suspense>
      );
    },
  },
  {
    path: slugs.transactionnft,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <TransactionNft />
        </Suspense>
      );
    },
  },
  {
    path: slugs.nft,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <NFTDetail />
        </Suspense>
      );
    },
  },
  {
    path: slugs.reward,
    main: function DashboardPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <RewardNFT />
        </Suspense>
      );
    },
  },
  {
    path: slugs.createNftManual,
    main: () => {
      return (
        <Suspense fallback={<Spinner />}>
          <CreateNftManual />
        </Suspense>
      );
    },
  },
  {
    path: slugs.notfound,
    main: function NotFoundPage() {
      return (
        <Suspense fallback={<Spinner />}>
          <h1>Not Found</h1>
        </Suspense>
      );
    },
  },
  {
    path: "*",
    main: function NoPage() {
      return <Navigate to={slugs.notfound} />;
    },
  },
];
