import { PayloadAction } from "@reduxjs/toolkit";
import { SellTransaction, SellTransactionUpdate } from "../../models/api";
import {
  GET_CONTENT_FINISH,
  GET_ALL_SELL_TRANSACTIONS_FINISH,
  UPDATE_SELL_TRANSACTION_FINISH,
  UPDATE_SELL_TRANSACTION,
} from "./data.type";

type InitialState = {
  content: Record<string, never>;
  sellTransactions: SellTransaction[];
  updateTransactionLoading: boolean;
};

const INITIAL_STATE: InitialState = {
  content: {},
  sellTransactions: [],
  updateTransactionLoading: true,
};

const dataReducer = (state = INITIAL_STATE, action: PayloadAction<any>) => {
  switch (action.type) {
    case GET_CONTENT_FINISH:
      return getContentFinish(state, action);
    case GET_ALL_SELL_TRANSACTIONS_FINISH:
      return getAllSellTransactionsFinish(state, action);
    case UPDATE_SELL_TRANSACTION:
      return updateSellTransaction(state);
    case UPDATE_SELL_TRANSACTION_FINISH:
      return updateSellTransactionFinish(state, action);
    default:
      return state;
  }
};

const getContentFinish = (
  state: InitialState,
  action: PayloadAction<Record<string, never>>
) => {
  return {
    ...state,
    content: action.payload,
  };
};

const getAllSellTransactionsFinish = (
  state: InitialState,
  action: PayloadAction<SellTransaction[]>
) => {
  return {
    ...state,
    sellTransactions: action.payload,
  };
};

const updateSellTransaction = (state: InitialState) => {
  return {
    ...state,
    updateTransactionLoading: true,
  };
};

const updateSellTransactionFinish = (
  state: InitialState,
  action: PayloadAction<SellTransactionUpdate | Record<string, any>>
) => {
  const transactionsWithoutUpdated: any = state.sellTransactions.filter(
    (tx) => tx.txId !== action.payload.txId
  );

  const transactionUpdated: any = state.sellTransactions.filter(
    (tx) => tx.txId === action.payload.txId
  );

  if (action.payload.txId && transactionUpdated.length > 0)
    transactionsWithoutUpdated.unshift({
      ...transactionUpdated[0],
      ...action.payload,
    });

  return {
    ...state,
    sellTransactions: transactionsWithoutUpdated,
    updateTransactionLoading: false,
  };
};

export default dataReducer;
