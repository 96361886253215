import React from "react";

const useApp = () => {
  // useState
  const [mobileOpen, setMobileOpen] = React.useState(false);

  // useMemo
  const drawerWidth = React.useMemo(() => 240, []);

  // Other functions
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return {
    mobileOpen,
    setMobileOpen,
    drawerWidth,
    handleDrawerToggle,
  };
};

export default useApp;
