//@ts-nocheck
import { axiosGroup } from ".";

export const getAllSellTransactions = async () => {
  const items = await axiosGroup.axiosServer.get("/allSellTransactions");

  return items.data.transactions.map((item) => {
    item.id = item.hash;

    return item;
  });
};

export const updateSellTransaction = async (data: any) => {
  await axiosGroup.axiosServer.patch("/sellTransaction", {
    ...data,
  });
};
