export const TX_STATUS = {
  Pending: "Pending",
  Success: "Success",
  Failed: "Failed",
  Expired: "Expired",
};

export const USER_STATUS = {
  Pending: "PENDING",
  Active: "ACTIVE",
  Suspend: "SUSPEND",
};

export const LAUNCHPAD_STATUS = {
  Pending: "pending",
  Cancelled: "cancelled",
  Approved: "approved",
};

export const NETWORK_NAMES = {
  BSC: "BSC",
};

export const TOAST_STATE = {
  Success: "success",
  Info: "info",
  Error: "error",
  Waring: "warning",
};

export const CONTRACT_NAMES = {
  PrivateSale: "private sale",
  Vesting: "vesting",
  TAL: "tal",
  USDT: "usdt",
  BNB: "bnb",
  RewardContract: 'reward',
};

export const FIREBASE_TABLE = {
  user: "users",
};

export const LOCKED_ROUND_STATUS = {
  Finished: "Finished",
  InProgress: "In Progress",
  NotStart: "Not Start",
};

interface IObjectKeys {
  [key: number]: string | number | undefined;
}

export const TOKEN_ROUND: IObjectKeys = {
  1: "240000000",
  2: "400000000",
  3: "640000000",
  4: "96000000",
  5: "24000000",
};

export const TOKEN_ROUND_NAME: IObjectKeys = {
  1: "SEED 1",
  2: "PRIVATE 1",
  3: "PRIVATE 2",
  4: "PUBLIC PRESALE",
  5: "PUBLIC IDO",
};

export const TOKEN_RATE: IObjectKeys = {
  1: 0.01,
  2: 0.02,
  3: 0.03,
  4: 0.04,
  5: 0.05,
};
