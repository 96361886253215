import React from "react";
import { Doughnut } from "react-chartjs-2";
import { ITokenChart } from "./type";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const TokenChart = ({ data }: ITokenChart) => {
  
  const dataChart = {
    labels: data?.map((item:any) => item?.labels),
    datasets: [
      {
        data: data?.map((item:any) => item?.data),
        backgroundColor:data?.map((item:any) => item?.color),
        borderColor:data?.map((item:any) => item?.color),
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut data={dataChart} options={options} />;
};

export default TokenChart;
