import { ethers } from "ethers";
import { LOCKED_ROUND_STATUS, TOAST_STATE } from "../const";
import { LockedRound } from "../models/blockchain";
import {
  convertFromEtherUnit,
  convertToEtherUnit,
  triggerToast,
} from "../utils";
import { getProvider } from "./provider";

const oneMonth = 2635200;
const provider = new ethers.providers.Web3Provider(getProvider(true));

export const buyTALTokenByCash = async (
  contract: any,
  talTokenAmount: string,
  account: string,
  currentRound: number
) => {
  const paidPercentage = Number(currentRound) === 4 ? "20" : "0";

  const gasLimit = await contract.estimateGas.buyTALTokenByBNB(
    convertToEtherUnit(talTokenAmount),
    currentRound,
    paidPercentage,
    account,
    false,
  );

  const currentGasPrice = await provider.getGasPrice();
  const gasPrice = ethers.utils.hexlify(parseInt(currentGasPrice.toString()));

  const overrides = {
    gasPrice,
    gasLimit,
  };

  const transaction = await contract.buyTALTokenByCash(
    convertToEtherUnit(talTokenAmount),
    currentRound,
    paidPercentage,
    account,
    false,
    overrides
  );

  triggerToast(
    "Your order is processing! Please wait for a whlie!",
    TOAST_STATE.Success
  );

  await transaction.wait();

  return transaction.hash;
};

export const getCurrentLockedRound = async (contract: ethers.Contract) => {
  try {
    const currentLockedStage = await contract.getCurrentLockedRound();

    return currentLockedStage.toString();
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const getLockedRounds = async (contract: ethers.Contract) => {
  try {
    const rounds = await contract.getRounds();

    return rounds;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getRoundSupplies = async (contract: ethers.Contract) => {
  try {
    const supplies = await contract.totalTalSupplies();

    const returnedSupplies: string[] = [];

    supplies.forEach((supply: any) => {
      returnedSupplies.push(convertFromEtherUnit(supply));
    });

    return returnedSupplies;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getLockedTokensAccount = async (
  contract: ethers.Contract,
  address: string
) => {
  try {
    const lockedTokens = await contract.getLockedTokensAccount(address);

    const lockedTokenArray: string[] = [];

    lockedTokens.forEach((token: any) => {
      lockedTokenArray.push(convertFromEtherUnit(token));
    });

    return lockedTokenArray;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getUnlockedTokensAccount = async (
  contract: ethers.Contract,
  address: string
) => {
  try {
    const unlockedTokens = await contract.getUnlockedTokensAccount(address);

    const unlockedTokenArray: string[] = [];

    unlockedTokens.forEach((token: any) => {
      unlockedTokenArray.push(convertFromEtherUnit(token));
    });

    return unlockedTokenArray;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getCurrentRound = async (contract: ethers.Contract) => {
  try {
    const currentStage = await contract.getCurrentRound();

    return currentStage.toString();
  } catch (error) {
    return "1";
  }
};

export const getAvailableUnlockedTokens = async (
  contract: ethers.Contract,
  address: string,
  round: number
) => {
  try {
    const tokens = await contract.getAvailableUnlockedTokens(address, round);

    return convertFromEtherUnit(tokens);
  } catch (error) {
    console.log(error);
    return "0";
  }
};

export const getCurrentLockedMonth = async (
  contract: ethers.Contract,
  round: string
) => {
  try {
    const month = await contract.getCurrentLockedMonth(round);

    return month.toString();
  } catch (error) {
    console.log(error);
    return "0";
  }
};

export const getLockedData = async (contract: ethers.Contract) => {
  try {
    const rounds = await getLockedRounds(contract);
    const supplies = await getRoundSupplies(contract);

    let currentRound = await getCurrentRound(contract);

    currentRound = Number(currentRound);

    const returnedData: LockedRound[] = [];

    for (let index = 0; index < rounds.length; index++) {
      let roundStatus = "";

      if (currentRound < index + 1) roundStatus = LOCKED_ROUND_STATUS.NotStart;
      else if (currentRound === index + 1)
        roundStatus = LOCKED_ROUND_STATUS.InProgress;
      else roundStatus = LOCKED_ROUND_STATUS.Finished;

      const data = {
        round: index + 1,

        status: roundStatus,
        supply: supplies[index] ? supplies[index] : "0.0",
        currentMonth: "",
      };

      const currentMonth = await getCurrentLockedMonth(
        contract,
        (index + 1).toString()
      );

      data.currentMonth = currentMonth;

      // Check the last round status
      if (
        index + 1 === rounds.length &&
        Number(currentMonth) * oneMonth + Number(rounds[index - 1]) >=
        Number(rounds[index])
      ) {
        data.status = LOCKED_ROUND_STATUS.Finished;
      }

      returnedData.push(data);
    }

    return returnedData;
  } catch (error) {
    console.log(error);
    return [];
  }
};
