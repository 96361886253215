import { NETWORK_NAMES } from "./enums";

export const networkConfig = {
  [NETWORK_NAMES.BSC]: {
    MAINNET_CHAIN_ID: process.env.REACT_APP_BSC_MAINNET_CHAIN_ID_DEX,
    MAINNET_RPC: process.env.REACT_APP_BSC_MAINNET,
    TESTNET_CHAIN_ID: process.env.REACT_APP_BSC_TESTNET_CHAIN_ID_DEX,
    TESTNET_RPC: process.env.REACT_APP_BSC_TESTNET,
    FDS_WALLET_ADDRESS: "0x211443B84E881F1Ca27Ac6Bb99c481618CdB3C24",
  },
};
