import React, { useRef, useEffect } from "react";

type PrivateProps = {
    children?: JSX.Element,
    callBack: any,
    className?: string
  };

function useOutsideAlerter(ref: any, callback: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [callback, ref]);
  }


  export function ClickOutSide({children, callBack, className} : PrivateProps) {

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, callBack);

  return (
    <div ref={wrapperRef} className={className}>{children}</div>
  )
}
