import { toast, ToastOptions } from "react-toastify";
// Const
import { TOAST_STATE } from "../const";

const toastData: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const triggerToast = (content: string, state: string) => {
  switch (state) {
    case TOAST_STATE.Success:
      toast.success(content, toastData);
      break;
    case TOAST_STATE.Info:
      toast.info(content, toastData);
      break;
    case TOAST_STATE.Error:
      toast.error(content, toastData);
      break;
    case TOAST_STATE.Waring:
      toast.warning(content, toastData);
      break;
    default:
      break;
  }
};
