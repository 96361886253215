import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
// Const
import { TOAST_STATE } from "../../const";
// Utils
import { triggerToast } from "../../utils";
// Store
import { userActions } from "../../store";

export const Header = () => {
  // useDispatch
  const dispatch = useDispatch();

  // useState
  const [ControllMenu, setControllMenu] = useState(false);
  const [selectLocale, setSelectLocale] = useState(false);

  // Other functions
  const handleLogout = () => {
    dispatch(userActions.updateUserAuthState(false));

    triggerToast("Logout succefully", TOAST_STATE.Success);
  };

  return (
    <div className=" flex justify-between items-center w-full font-raleway700 text-white ">
      <h1 className="text-[23px]">Dashboard</h1>
      <div>
        <ul className="textWhite flex items-center ">
          <li className="px-[13px] relative text-lg">
            <span
              onClick={() => {
                setSelectLocale(!selectLocale);
              }}
              className="hover:border-b-2 hover:border-white cursor-pointer"
            >
              Eng
            </span>
            <ul
              className={`${
                selectLocale ? "" : "hidden"
              } absolute left-0 right-0 top-[100%] shadow-2xl bg-seventh cursor-pointer`}
            >
              <li className="text-center py-2 opacity-60 hover:underline activeElement ">
                Eng
              </li>
              <li className="text-center py-2 opacity-60 hover:underline">
                Ger
              </li>
            </ul>
          </li>
          {/* <li className="px-[13px]"><DarkModeIcon/></li> */}
          <li className="px-[13px] ml-[13px]">
            <div className="flex items-center">
              <span className="rounded-[50%] w-[38px] h-[38px] overflow-hidden">
                <img
                  src="/resource/images/default-avatar.png"
                  alt=""
                  className="w-[38px] h-[38px]"
                />
              </span>
              <span
                onClick={() => {
                  setControllMenu(!ControllMenu);
                }}
                className="px-[13px] relative"
              >
                <ExpandMoreIcon className="text-first cursor-pointer" />
                <ul
                  className={`${
                    ControllMenu ? "" : "hidden"
                  } flex flex-col justify-center absolute top-[170%] right-0 shadow-2xl bg-[#ccc] `}
                >
                  <li
                    className="flex text-white w-full py-2  bg-ninth cursor-pointer hover:bg-tenth hover:text-first"
                    onClick={handleLogout}
                  >
                    <span className="mx-2">Logout</span>
                    <LogoutIcon className="mx-2" />
                  </li>
                </ul>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
