import SearchIcon from "@mui/icons-material/Search";
import { Box, MenuItem, Select, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  approveNFTsAPI,
  cancelNFTsAPI,
  getNFTsAPI,
} from "../api/launchpadAPIs";
import { NFTLaunchpad } from "../models/blockchain";
import { columns } from "../schema/nft";

export const NFTs = () => {
  const [value, setValue] = useState("all");

  //search
  const [searchData, setSearchData] = useState("");
  const [nfts, setNFTs] = useState<NFTLaunchpad[]>([]);
  const [loadingNFT, setLoadingNFT] = useState(false);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    async function getNFT() {
      setLoadingNFT(true);
      const res = await getNFTsAPI();

      let arr = await res?.map((item: NFTLaunchpad, index: number) => {
        let date_time = "";
        if (item.time) {
          date_time = item?.time;
        }
        return {
          id: index,
          nftStorageId: item?.nftStorageId,
          amount: item?.amount,
          owner: item?.ownerId,
          status: item?.status,
          name: item?.metadata?.properties?.player?.name,
          time: date_time,
        };
      });
      arr.sort(function (a: any, b: any) {
        return b.time - a.time;
      });
      setNFTs(arr);
      setLoadingNFT(false);
    }

    getNFT();
  }, []);

  const handleCancel = async (nftStorageId: string) => {
    setLoadingNFT(true);

    try {
      await cancelNFTsAPI(nftStorageId);

      const res = await getNFTsAPI();
      setNFTs(res);
    } catch (error) {
      alert(error);
      console.error(error);
    }

    setLoadingNFT(false);

    window.location.reload();
  };

  const handleChangeSearch = (e: any) => setSearchData(e.target.value);

  const handleApproved = async (nftStorageId: string) => {
    setLoadingNFT(true);

    try {
      await approveNFTsAPI(nftStorageId);

      const res = await getNFTsAPI();
      setNFTs(res);
    } catch (error) {
      console.error(error);
    }

    setLoadingNFT(false);
    window.location.reload();
  };

  return (
    <div className="p-10 bg-seventh rounded shadow">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <div className="flex flex-col xl:flex-row justify-between py-[10px]">
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Stack sx={{ position: "relative", top: 0, left: 0 }}>
              <h1 className="font-montserrat800 text-[26px]" color="#000">
                All NFTs
              </h1>
            </Stack>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Stack sx={{ marginX: "10px" }}>
                <Select
                  label="Status"
                  defaultValue={value}
                  value={value}
                  onChange={handleChange}
                >
                  <MenuItem value={"all"}>{"All"}</MenuItem>
                  <MenuItem value={"pending"}>{"Pending"}</MenuItem>
                  <MenuItem value={"approved"}>{"Approved"}</MenuItem>
                  <MenuItem value={"cancelled"}>{"Cancelled"}</MenuItem>
                </Select>
              </Stack>
              <div className="flex mb-4 xl:mb-0">
                <input
                  placeholder="Tranx ID to quick search"
                  type="text"
                  className="bg-seventh overflow-hidden border-t border-l border-b border-twentyTwo rounded-l-[8px] py-4 px-6"
                  onChange={handleChangeSearch}
                />
                <span className=" py-4 px-6 h-full flex justify-center items-center border-twentyTwo rounded-r-[8px] border-t border-r border-b ">
                  <SearchIcon className="text-fourth " />
                </span>
              </div>
            </Stack>
          </Stack>
        </div>
        <div>
          <DataGrid
            rows={nfts
              ?.filter((item) => {
                if (value === "all") return true;
                return item?.status === value;
              })
              ?.filter(
                (nft: any) =>
                  nft?.nftStorageId
                    ?.toLowerCase()
                    .includes(searchData.toLowerCase()) ||
                  nft?.name?.toLowerCase().includes(searchData.toLowerCase()) ||
                  nft?.owner?.toLowerCase().includes(searchData.toLowerCase())
              )}
            loading={loadingNFT}
            columns={columns(handleCancel, handleApproved)}
            sx={{ minHeight: 500, borderColor: "#000" }}
          />
        </div>
      </Box>
    </div>
  );
};
