export const errorHandler = (error: string, contentFromAPI: any) => {
  switch (true) {
    case error.includes("auth") &&
      (error.includes("not-found") || error.includes("password")):
      return contentFromAPI.USER_NOT_EXIST;
    case error.includes("auth") && error.includes("popup-closed"):
      return contentFromAPI.CANCEL_POPUP;
    case error.includes("refuses to grant permission"):
      return contentFromAPI.CANCEL_POPUP;
    default:
      if (process.env.NODE_ENV === "development")
        return "Unexpected error! " + error;
      else return "Something's wrong. Please try it again!";
  }
};
