import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import {
  CONTRACT_NAMES,
  NETWORK_NAMES,
  TOAST_STATE,
  networkConfig,
} from "../const";
import { triggerToast } from "../utils";
import { connectMetamask, getContract, getContractOnlyRead } from "../wallet";
// import DateTimePicker from 'react-datetime-picker';

// type DefaultTimeType = 'today' | 'month' | 'year' | 'all'

export const RewardNFT = () => {
  const [rewardPoint, setRewardPoint] = useState<number>(0);
  const [totalSupply, setTotalSupply] = useState<number>(0);
  const web3Object = useWeb3React();

  const handleAddRewardPoint = (e: any) => {
    setRewardPoint(e.target.value);
  };

  const handleSubmitReward = async () => {
    try {
      const contract = getContract(
        web3Object.account,
        CONTRACT_NAMES.RewardContract,
        true
      );
      const responseReward = await contract.fundTalSupply(Number(rewardPoint));
      await responseReward.wait();
      triggerToast("Fund TAL successfully", TOAST_STATE.Success);
    } catch (error: any) {
      triggerToast("Somthing went wrong, please try again", TOAST_STATE.Error);
    }
  };

  const handleConnectWallet = async () => {
    await connectMetamask(web3Object);
  };

  useEffect(() => {
    const getTotalSupply = async () => {
      try {
        const contract = getContractOnlyRead(
          CONTRACT_NAMES.RewardContract,
          NETWORK_NAMES.BSC,
          networkConfig[NETWORK_NAMES.BSC].MAINNET_CHAIN_ID
        );
        const response = await contract.getTalSupply();
        setTotalSupply(response);
      } catch (error) {
        console.error(error);
      }
    };
    if (web3Object.account) {
      getTotalSupply();
    }
  }, [web3Object]);

  return (
    <div className="p-10 rounded">
      <Stack sx={{ boxShadow: 5, padding: "10px", background: "#eee" }}>
        {web3Object?.account ? (
          <Stack sx={{ padding: "10px" }}>
            <Typography>{`Total TAL: ${totalSupply}`}</Typography>
            <Typography>Add Reward TAL</Typography>

            <TextField
              type="number"
              sx={{ marginY: 1 }}
              value={rewardPoint}
              onChange={handleAddRewardPoint}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">TAL</InputAdornment>
                ),
              }}
            />
            <Button onClick={handleSubmitReward} variant="contained">
              {" "}
              Add Reward
            </Button>
          </Stack>
        ) : (
          <Stack sx={{ padding: "10px" }}>
            <Typography>Please connect wallet to continue</Typography>
            <Button onClick={handleConnectWallet} variant="contained">
              {" "}
              Connect Wallet
            </Button>
          </Stack>
        )}
      </Stack>
    </div>
  );
};
