import { BigNumber, Contract, utils } from "ethers";
import { useState } from "react";

export const GetToken = (contract: Contract, address: string) => {
  const [token, setToken] = useState<string>();

  if (!address) return "No wallet";

  const getToken = async (address: string) => {
    await contract?.balanceOf(address).then((res: BigNumber) => {
      setToken(`${utils.formatEther(BigNumber.from(res?._hex))} TAL`);
    });
  };

  getToken(address);
  return token;
};
