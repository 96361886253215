import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import userReducer from "./user/user.reducer";
import dataReducer from "./data/data.reducer";
import { history } from "../utils";
import { persistReducer } from "redux-persist";
import expireReducer from "redux-persist-expire";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
  transforms: [
    expireReducer("user", {
      expireSeconds: 7200,
      expiredState: {
        isAuthenticated: false,
      },
      autoExpire: true,
    }),
  ],
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  data: dataReducer,
});

export default persistReducer(persistConfig, rootReducer);
