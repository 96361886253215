import { PayloadAction } from "@reduxjs/toolkit";
import { Contract } from "ethers";
import { call, put, takeLeading } from "redux-saga/effects";
import {
  getAllSellTransactions,
  getContentAPI,
  updateSellTransaction,
} from "../../api";
import { CONTRACT_NAMES, TOAST_STATE } from "../../const";
import { SellTransaction, SellTransactionUpdate } from "../../models/api";
import { triggerToast } from "../../utils";
import { buyTALTokenByCash, getContract } from "../../wallet";
import { dataActions } from "./data.action";
import {
  GET_ALL_SELL_TRANSACTIONS,
  GET_CONTENT,
  UPDATE_SELL_TRANSACTION,
} from "./data.type";

function* handleGetContent(getContentAction: PayloadAction<string>) {
  try {
    const result: Record<string, never> = yield call(
      getContentAPI,
      getContentAction.payload
    );

    yield put(dataActions.getContentFinish(result));
  } catch (error) {
    yield put(dataActions.getContentFinish({}));
  }
}

function* handleGetAllSellTransactions() {
  try {
    const result: SellTransaction[] = yield call(getAllSellTransactions);

    yield put(dataActions.getAllSellTransactionsFinish(result));
  } catch (error) {
    yield put(dataActions.getAllSellTransactionsFinish([]));
  }
}

function* handleUpdateSellTransaction(
  action: PayloadAction<SellTransactionUpdate>
) {
  try {
    let data: any = action.payload;

    if (data.status === "Success") {
      const contract: Contract = yield call(
        getContract,
        data.contract?.signer,
        CONTRACT_NAMES.PrivateSale,
        true
      );

      const hash: string = yield call(
        buyTALTokenByCash,
        contract,
        data.contract?.amount || "",
        data.contract?.account || "",
        data.contract?.currentRoundValue
      );

      delete data.contract;

      data = {
        ...data,
        hash,
      };
    }

    yield call(updateSellTransaction, data);

    triggerToast("Update transactions successfully", TOAST_STATE.Success);

    yield put(dataActions.updateSellTransactionFinish(data));
  } catch (error) {
    triggerToast("Your request is failed", TOAST_STATE.Error);
    yield put(dataActions.updateSellTransactionFinish({}));
  }
}

export default function* dataSaga() {
  yield takeLeading(GET_CONTENT, handleGetContent);
  yield takeLeading(GET_ALL_SELL_TRANSACTIONS, handleGetAllSellTransactions);
  yield takeLeading(UPDATE_SELL_TRANSACTION, handleUpdateSellTransaction);
}
