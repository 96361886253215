import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  approveLaunchpadsAPI,
  cancelLaunchpadsAPI,
  getLaunchpadsAPI,
} from "../api/launchpadAPIs";
import { Tabs1 } from "../components";
import { StyledDataGrid } from "../components/Tables/styledComponents";
import { LAUNCHPAD_STATUS } from "../const";
import { NFTLaunchpad } from "../models/blockchain";
import { columns } from "../schema/launchpad";

export const Launchpad = () => {
  const [value, setValue] = useState("1");

  //search
  const [launchpads, setLaunchpads] = useState<NFTLaunchpad[]>([]);
  const [loadingLaunchpad, setLoadingLaunchpad] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    async function getLaunchPad() {
      setLoadingLaunchpad(true);
      const res = await getLaunchpadsAPI();
      setLaunchpads(
        res?.map((item: NFTLaunchpad, index: number) => {
          return {
            id: item?.nftLaunchpadId?.nftLaunchpadId,
            closingDate: new Date(item?.expriredDate as number),
            price: item?.price,
            owner: item?.ownerId,
            status: item?.status,
          };
        })
      );
      setLoadingLaunchpad(false);
    }

    getLaunchPad();
  }, []);

  const handleCancel = async (preOrderId: string) => {
    setLoadingLaunchpad(true);

    await cancelLaunchpadsAPI(preOrderId);

    const res = await getLaunchpadsAPI();
    setLaunchpads(res);

    setLoadingLaunchpad(false);

    window.location.reload();
  };

  const handleApproved = async (preOrderId: string) => {
    setLoadingLaunchpad(true);

    await approveLaunchpadsAPI(preOrderId);

    const res = await getLaunchpadsAPI();
    setLaunchpads(res);

    setLoadingLaunchpad(false);
    window.location.reload();
  };

  console.log(launchpads);

  return (
    <div className="text-white">
      <div className="flex flex-col xl:flex-row justify-between py-[42px]">
        <h1 className="font-montserrat800 text-[26px]">Launchpad</h1>
        <div className="flex flex-col xl:flex-row ">
          <div className="flex mr-4 mb-4 xl:mb-0">
            {/* <input
              // onChange={handleChangeSearch}
              placeholder="Quick search"
              type="text"
              className="bg-seventh overflow-hidden border-t border-l border-b border-twentyTwo rounded-l-[8px] py-4 px-6"
            />
            <span className=" py-4 px-6 h-full flex justify-center items-center border-twentyTwo rounded-r-[8px] border-t border-r border-b ">
              <SearchIcon className="text-fourth " />
            </span> */}
          </div>
        </div>
      </div>
      <div className="px-10 lg:px-[100px] xxl:px-[200px]">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "#2F304B",
                paddingBottom: "20px",
                overFlowX: "scroll",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tabs1 label="Waitting" value="1" />
                <Tabs1 label="Approved" value="2" />
                <Tabs1 label="Rejected" value="3" />
              </TabList>
            </Box>
            <div>
              <TabPanel value="1">
                <StyledDataGrid
                  loading={(loadingLaunchpad as boolean) || !launchpads}
                  rows={launchpads?.filter(
                    (item) => item?.status === LAUNCHPAD_STATUS.Pending
                  )}
                  columns={columns(handleCancel, handleApproved)}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  sx={{ minHeight: 500 }}
                />
              </TabPanel>
              <TabPanel value="2">
                <StyledDataGrid
                  loading={(loadingLaunchpad as boolean) || !launchpads}
                  rows={launchpads?.filter(
                    (item) => item?.status === LAUNCHPAD_STATUS.Approved
                  )}
                  columns={columns(handleCancel, handleApproved)}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  sx={{ minHeight: 500 }}
                />
              </TabPanel>
              <TabPanel value="3">
                <StyledDataGrid
                  loading={(loadingLaunchpad as boolean) || !launchpads}
                  rows={launchpads?.filter(
                    (item) => item?.status === LAUNCHPAD_STATUS.Cancelled
                  )}
                  columns={columns(handleCancel, handleApproved)}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  sx={{ minHeight: 500 }}
                />
              </TabPanel>
            </div>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
