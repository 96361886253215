import axios from "axios";
import jwt_decode from "jwt-decode";

let strapiToken = "";

export type TErrorAxios = {
  error: string;
  statusCode: number;
  message: string;
};

const axiosServer = axios.create({
  baseURL: process.env.REACT_APP_SERVER_LINK,
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosServerAPI = axios.create({
  baseURL: process.env.REACT_APP_API_LINK,
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosCms = axios.create({
  baseURL: process.env.REACT_APP_CMS_LINK,
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosBSC = axios.create({
  baseURL: process.env.REACT_APP_BSC_API_LINK,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosCms.interceptors.request.use(
  async (request) => {
    request.headers = {
      Authorization: `Bearer ${strapiToken}`,
    };

    // Edit request config
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axiosServerAPI.interceptors.request.use(
  (config) => {
    if (typeof window !== "undefined") {
      const access_token = localStorage.getItem("accessToken") || null;
      if (!access_token) {
        window.location.href = "/";
      } else {
        const token: { exp: number } = jwt_decode(access_token as any);
        const { exp } = token;
        if (exp < Date.now() / 1000) {
          localStorage.clear();
          window.location.reload();
        }
      }

      // config.headers!.cookies = `${access_token}`;
      config.headers!.Authorization = `Bearer ${access_token}`;

      return config;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error.response as TErrorAxios);
  }
);

// Functions
const getToken = async () => {
  try {
    const responseFromCms = await axios.post(
      process.env.REACT_APP_CMS_LINK + "/auth/local",
      {
        identifier: process.env.REACT_APP_STRAPI_IDENTIFIER,
        password: process.env.REACT_APP_STRAPI_PASSWORD,
      }
    );
    strapiToken = responseFromCms.data.jwt;
  } catch (error) {}
};

export const axiosGroup = {
  axiosCms,
  axiosBSC,
  axiosServerAPI,
  axiosServer,
  functions: {
    getToken,
  },
};
