import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import { StyledProps } from "../../models";

export const Tabs1 = styled(Tab)(({ theme }: StyledProps) => ({
  fontSize: 20,
  color: theme?.colors.fifth,
  padding: '0 40px',
  fontFamily: theme?.fontFamilies.raleway500,
  "&.Mui-selected": {
  },
}));
