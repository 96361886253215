import { createTheme } from "@mui/material";
// Models
import { CustomThemeProps } from "../models";
// Others
import { customConfig, defaultConfig } from "./commonConfig";

const defaultTheme = createTheme(defaultConfig);

export const DarkTheme: CustomThemeProps = {
  ...customConfig,
  ...defaultTheme,
};
