import { UPDATE_USER_AUTH_STATE } from "./user.type";

const updateUserAuthState = (value: boolean) => ({
  type: UPDATE_USER_AUTH_STATE,
  payload: value,
});

export const userActions = {
  updateUserAuthState,
};
