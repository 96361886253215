import {
  GET_CONTENT,
  GET_CONTENT_FINISH,
  GET_ALL_SELL_TRANSACTIONS,
  GET_ALL_SELL_TRANSACTIONS_FINISH,
  UPDATE_SELL_TRANSACTION,
  UPDATE_SELL_TRANSACTION_FINISH,
} from "./data.type";
import { SellTransaction, SellTransactionUpdate } from "../../models/api";

const getContent = (locale: string) => ({
  type: GET_CONTENT,
  payload: locale,
});

const getContentFinish = (result: Record<string, never>) => ({
  type: GET_CONTENT_FINISH,
  payload: result,
});

const getAllSellTransactions = () => ({
  type: GET_ALL_SELL_TRANSACTIONS,
});

const getAllSellTransactionsFinish = (data: SellTransaction[]) => ({
  type: GET_ALL_SELL_TRANSACTIONS_FINISH,
  payload: data,
});

const updateSellTransaction = (data: SellTransactionUpdate) => ({
  type: UPDATE_SELL_TRANSACTION,
  payload: data,
});

const updateSellTransactionFinish = (
  data: SellTransactionUpdate | Record<string, any>
) => ({
  type: UPDATE_SELL_TRANSACTION_FINISH,
  payload: data,
});

export const dataActions = {
  getContent,
  getContentFinish,
  getAllSellTransactions,
  getAllSellTransactionsFinish,
  updateSellTransaction,
  updateSellTransactionFinish,
};
