import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
// import logger from "redux-logger";
import { persistStore } from "redux-persist";

import rootReducer from "./root.reducer";
import rootSaga from "./root.saga";

import { history } from "../utils";

const sagaMiddleware = createSagaMiddleware();

let middleware = [];
if (process.env.NODE_ENV === "development") {
  middleware = [sagaMiddleware];
} else {
  middleware = [sagaMiddleware];
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware, routerMiddleware(history)))
);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
