import SearchIcon from "@mui/icons-material/Search";
import { Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  approveNFTsAPI,
  cancelNFTsAPI,
  getNFTsAPI,
  getTransactionNFTsAPI,
} from "../api/launchpadAPIs";
import { transactionNFT } from "../models/blockchain";
import { columnsTransactionNft } from "../schema/nft";
// import DateTimePicker from 'react-datetime-picker';

import { DataGrid } from "@mui/x-data-grid";
import { DateRange, Range } from "react-date-range";

type DefaultTimeType = "today" | "month" | "year" | "all";

export const TransactionNft = () => {
  //search
  const [nfts, setNFTs] = useState<transactionNFT[]>([]);
  const [filterNFTs, setFilterNFTs] = useState<transactionNFT[]>([]);
  const [loadingNFT, setLoadingNFT] = useState(false);
  const [isDefaultChoose, setDefaultChoose] =
    useState<DefaultTimeType>("today");
  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const sumfeeTALAmountValue = () => {
    let sum = 0;
    filterNFTs?.map((item: any) => (sum = sum + item.feeTALAmount));
    return sum / 10e17;
  };
  const sumSellerTALAmount = () => {
    let sum = 0;
    filterNFTs?.map((item: any) => (sum = sum + item.sellerTALAmount));
    return sum / 10e17;
  };
  const sumTALAmount = () => {
    return sumSellerTALAmount() + sumfeeTALAmountValue();
  };

  const handleDefaultStateChange = (type: DefaultTimeType) => {
    const today = new Date();
    setDefaultChoose(() => type);
    switch (type) {
      case "today":
        const startDay = new Date();
        startDay.setHours(0, 0, 0, 0);
        setState([
          {
            startDate: startDay,
            endDate: today,
            key: "selection",
          },
        ]);
        break;
      case "month":
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setState([
          {
            startDate: firstDayOfMonth,
            endDate: lastDayOfMonth,
            key: "selection",
          },
        ]);
        break;
      case "year":
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
        setState([
          {
            startDate: firstDayOfYear,
            endDate: lastDayOfYear,
            key: "selection",
          },
        ]);
        break;
      default:
        setState([
          {
            startDate: new Date("01/01/2000"),
            endDate: today,
            key: "selection",
          },
        ]);
        break;
    }
  };

  const handleStateOnChange = (item: any) => {
    const states = [item.selection];
    setState(states);
  };

  const handleCancel = async (nftStorageId: string) => {
    setLoadingNFT(true);

    await cancelNFTsAPI(nftStorageId);

    const res = await getNFTsAPI();
    setFilterNFTs(res);

    setLoadingNFT(false);

    window.location.reload();
  };

  const handleChangeSearch = (e: any) => {
    let text = e.target.value;
    if (!text) return nfts;
    let a = nfts?.filter(
      (f) =>
        f?.transaction_hash?.toLowerCase().includes(text.toLowerCase()) ||
        f?.from_address?.toLowerCase().includes(text.toLowerCase()) ||
        f?.to_address?.toLowerCase().includes(text.toLowerCase())
    );
    setFilterNFTs(a);
  };

  const handleApproved = async (nftStorageId: string) => {
    setLoadingNFT(true);

    await approveNFTsAPI(nftStorageId);

    const res = await getNFTsAPI();
    setNFTs(res);

    setLoadingNFT(false);
    window.location.reload();
  };

  const buttonValues: { title: string; value: DefaultTimeType }[] = [
    {
      title: "Today",
      value: "today",
    },
    {
      title: "This Month",
      value: "month",
    },
    {
      title: "This Year",
      value: "year",
    },
    {
      title: "All",
      value: "all",
    },
  ];

  useEffect(() => {
    async function getNFT() {
      setLoadingNFT(true);
      try {
        const res = await getTransactionNFTsAPI();

        const responseArr = res?.map((item: any, index: number) => {
          return {
            id: index + 1,
            block_number: item?.NFTTransferLog.blockNumber,
            transaction_index: item?.transactionIndex,
            transaction_hash: item?.transactionHash,
            from_address: item?.NFTTransferLog.from,
            to_address: item?.NFTTransferLog.to,
            value: item?.value,
            receipt_gas_used: item?.gasUsed,
            gas_price: item?.gasPrice,
            amount: item?.amount,
            block_timestamp: item?.NFTTransferLog.timeStamp,
            revenue_fee: item?.NFTTransferLog.feeTALAmount,
            ...item?.NFTTransferLog,
          };
        });
        setNFTs(responseArr);
        setFilterNFTs(responseArr);
      } catch (error) {
        console.error(error);
      }
      setLoadingNFT(false);
    }
    getNFT();
  }, []);

  useEffect(() => {
    let startDateTime = new Date(state[0].startDate?.toISOString() as any);
    let endDateTime = new Date(state[0].endDate?.toISOString() as any);

    if (startDateTime && endDateTime) {
      if (startDateTime.getTime() === endDateTime.getTime()) {
        endDateTime.setHours(23, 59, 59, 99);
      }

      const tmpNFTs = nfts?.filter((nft) => {
        const time = (nft.block_timestamp as number) * 10e2;
        const transactionDate = new Date(time);

        return (
          transactionDate.getTime() >= startDateTime.getTime() &&
          transactionDate.getTime() <= endDateTime.getTime()
        );
      });
      setFilterNFTs(() => tmpNFTs);
    }
  }, [state, nfts]);

  return (
    <div className="p-10 bg-seventh rounded shadow">
      <Stack sx={{ flexDirection: "row" }}>
        <Stack sx={{ marginRight: "10px", justifyContent: "flex-end" }}>
          <Stack paddingTop={1}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: "18px", color: "#000000" }}
            >
              Amount: <span>{sumTALAmount()} TAL</span>
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", fontSize: "18px", color: "#000000" }}
            >
              User Profits: <span>{sumSellerTALAmount()} TAL</span>
            </Typography>
            <Typography
              sx={{ fontWeight: "bold", fontSize: "18px", color: "#000000" }}
            >
              Revenue: <span>{sumfeeTALAmountValue()} TAL</span>
            </Typography>
          </Stack>
          <Stack>
            <ButtonGroup
              sx={{ width: "100%", flex: 1 }}
              variant="contained"
              aria-label="outlined primary button group"
            >
              {buttonValues.map((item) => (
                <Button
                  key={item.value}
                  color={isDefaultChoose === item.value ? "primary" : "info"}
                  onClick={() => handleDefaultStateChange(item.value)}
                >
                  {item.title}
                </Button>
              ))}
            </ButtonGroup>
            <Stack boxShadow={5} marginTop={1} height={"400px"}>
              <DateRange
                color="3944BC"
                editableDateInputs={true}
                onChange={handleStateOnChange}
                moveRangeOnFirstSelection={false}
                ranges={state}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ flexGrow: 1 }}>
          <div className="flex flex-col xl:flex-row justify-between py-[10px]">
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Stack sx={{ position: "relative", top: 0, left: 0 }}>
                <h1 className="font-montserrat800 text-[26px]" color="#000">
                  All Transaction NFTs
                </h1>
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div className="flex mb-4 xl:mb-0">
                  <input
                    placeholder="Tranx ID to quick search"
                    type="text"
                    className="bg-seventh overflow-hidden border-t border-l border-b border-twentyTwo rounded-l-[8px] py-4 px-6"
                    onChange={handleChangeSearch}
                  />
                  <span className=" py-4 px-6 h-full flex justify-center items-center border-twentyTwo rounded-r-[8px] border-t border-r border-b ">
                    <SearchIcon className="text-fourth " />
                  </span>
                </div>
              </Stack>
            </Stack>
          </div>
          <div>
            <DataGrid
              rows={filterNFTs || []}
              loading={loadingNFT}
              columns={columnsTransactionNft(handleCancel, handleApproved)}
              sx={{ minHeight: 500, borderColor: "#000" }}
            />
          </div>
        </Stack>
      </Stack>
    </div>
  );
};
