import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Paper,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// SCSS
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
// CSS
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// Icons
import MenuIcon from "@mui/icons-material/Menu";
// Containers
import { DrawerContainer, Header } from "./containers";
// Models
import { RouteProps } from "./models";
// Store
import { dataActions, getUserAuthState } from "./store";
// Hooks
import useApp from "./useApp";
// Others
import { axiosGroup } from "./api";
import Login from "./auth/Login";
import { routes } from "./routes";
import { LightTheme } from "./theme";

//chart
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  PointElement,
  LineElement,
  ArcElement
);

const App = () => {
  // useDispatch
  const dispatch = useDispatch();

  // Hooks
  const { drawerWidth, mobileOpen, handleDrawerToggle } = useApp();

  // useSelector
  const isAuthenticated = useSelector(getUserAuthState);

  // useEffect
  useEffect(() => {
    async function getDataFromCms() {
      try {
        await axiosGroup.functions.getToken();

        dispatch(dataActions.getContent("en"));
      } catch (error) {
        console.error(error);
      }
    }

    getDataFromCms();
  }, [dispatch]);

  // Other functions
  const showRoutes = () => {
    let result = null;

    if (routes.length > 0) {
      result = routes.map((route: RouteProps, index: number) => {
        return <Route key={index} path={route.path} element={route.main()} />;
      });
    }

    return result;
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const MainComponent = ({ children }: any) => (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
      }}
      className="bg-ninth min-h-screen min-w-fit"
    >
      {children}
    </Box>
  );

  return (
    <ThemeProvider theme={LightTheme}>
      <ToastContainer />
      <div className="App">
        <Paper sx={{ borderRadius: 0, display: "flex" }}>
          {isAuthenticated ? (
            <>
              <CssBaseline />
              <AppBar
                position="fixed"
                sx={{
                  width: { sm: `calc(100% - ${drawerWidth}px)` },
                  ml: { sm: `${drawerWidth}px` },
                }}
              >
                <Toolbar style={{ background: "#171832" }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: "none" } }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <div className="w-full">
                    <Header />
                  </div>
                </Toolbar>
              </AppBar>
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              >
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                >
                  <DrawerContainer />
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                  open
                >
                  <DrawerContainer />
                </Drawer>
              </Box>
              <MainComponent>
                <Toolbar />
                <Routes>{showRoutes()}</Routes>
              </MainComponent>
            </>
          ) : (
            <MainComponent>
              <Login />
            </MainComponent>
          )}
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default App;
