import { TOAST_STATE } from "../const";
import { triggerToast } from "../utils";

export const errorHandler = (error: string) => {
  switch (true) {
    case error.toLowerCase().includes("unsupported chain"):
      return "Please switch wallet network to BSC mainnet.";
    case error.toLowerCase().includes("user rejected"):
      return "Reject request!";
    default:
      return "Something's wrong while connecting...";
  }
};

export const handleActivateError = (error: Error) => {
  const message = errorHandler(error.message);
  triggerToast(message, TOAST_STATE.Error);
};
