import { ethers } from "ethers";
import { contractAddresses, contractABIs } from "../const";
import { getProvider } from "./provider";

export const getContract = (
  account: any,
  contractName: string,
  connectToMetamask: boolean,
  networkName?: string,
  chainId?: string
) => {


  const provider = new ethers.providers.Web3Provider(
    getProvider(connectToMetamask, networkName, chainId)
  );

  const contractAddress = contractAddresses[contractName];
  const contractAbi = contractABIs[contractName];

  const signer = provider.getSigner(account);
  return new ethers.Contract(contractAddress, contractAbi, signer);
};

export const getContractOnlyRead = (
  contractName: string,
  networkName?: string,
  chainId?: string
) => {
  const provider = getProvider(false, networkName, chainId);

  const contractAddress = contractAddresses[contractName];
  const contractAbi = contractABIs[contractName];

  return new ethers.Contract(contractAddress, contractAbi, provider);
};
