export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SEEDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
export const secondFirebaseConfig = {
  apiKey: process.env.REACT_APP_SECOND_API_KEY,
  authDomain: process.env.REACT_APP_SECOND_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_SECOND_PROJECT_ID,
  storageBucket: process.env.REACT_APP_SECOND_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SECOND_MESSAGING_SEEDER_ID,
  appId: process.env.REACT_APP_SECOND_APP_ID,
  measurementId: process.env.REACT_APP_SECOND_MEASUREMENT_ID,
};
