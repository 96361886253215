import { styled } from "@mui/system";
import { StyledProps } from "../../models";

export const StyledParagraph1 = styled("p")(({ theme }: StyledProps) => ({
  fontFamily: theme?.fontFamilies.raleway500,
  fontSize: 27,
}));

export const StyledParagraph2 = styled("p")(({ theme }: StyledProps) => ({
  fontFamily: theme?.fontFamilies.raleway500,
  fontSize: 20,
}));

export const StyledParagraph3 = styled("p")(({ theme }: StyledProps) => ({
  fontFamily: theme?.fontFamilies.raleway500,
  fontSize: 18,
}));

export const StyledParagraph4 = styled("p")(({ theme }: StyledProps) => ({
  fontFamily: theme?.fontFamilies.raleway500,
  fontSize: 14,
}));
