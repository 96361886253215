import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
// Const
import { TOAST_STATE } from "../const";
// Utils
import { errorHandler, triggerToast } from "../utils";
// Store
import { userActions, store } from "../store";
// Firebase
import { auth, db } from ".";

const signInWithEmailPassword = async (
  email: string,
  password: string,
  contentFromAPI: any
) => {
  try {
    // Get account data
    const q = query(collection(db, "admins"), where("email", "==", email));
    const accountDocs = await getDocs(q);

    if (accountDocs.docs.length > 0) {
    const accountDataFromFirebase =  await signInWithEmailAndPassword(auth, email, password);

      const token = await accountDataFromFirebase.user.getIdToken(false);

      localStorage.setItem("accessToken", token);

      store.dispatch(userActions.updateUserAuthState(true));

      triggerToast(contentFromAPI["SIGNIN_SUCCESS"], TOAST_STATE.Success);
      return true;
    }

    triggerToast(contentFromAPI["USER_NOT_EXIST"], TOAST_STATE.Error);

    return false;
  } catch (err: any) {
    triggerToast(errorHandler(err.message, contentFromAPI), TOAST_STATE.Error);
    return false;
  }
};

export { signInWithEmailPassword };
