import { Web3ReactContextInterface } from "@web3-react/core/dist/types";
import { getProvider, handleActivateError, injected } from ".";

export const connectMetamask = async ({
  activate,
}: Web3ReactContextInterface) => {
  const provider = getProvider(true);

  if (provider) {
    try {
      // Switch to suitable network
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.REACT_APP_BSC_MAINNET_CHAIN_ID_HEX }],
      });

      await handleActivate(activate);
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "BSC mainnet",
                chainId: process.env.REACT_APP_BSC_MAINNET_CHAIN_ID_HEX,
                rpcUrls: [process.env.REACT_APP_BSC_MAINNET],
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: Number(process.env.REACT_APP_BSC_MAINNET_DECIMALS),
                },
                blockExplorerUrls: [process.env.REACT_APP_BSC_MAINNET_EXPLORER],
              },
            ],
          });

          await handleActivate(activate);
        } catch (addError: any) {
          handleActivateError(addError);
          // handle "add" error
        }
      }
      // handle other "switch" errors
      handleActivateError(switchError);
    }
  }
};

const handleActivate = async (activate: any) => {
  await activate(injected, handleActivateError);
};
