import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/system";
import { StyledProps } from "../../models";

export const StyledLoadingButton = styled(LoadingButton)(
  ({ theme }: StyledProps) => ({
    background: '#60EAEC',
    color: "black",
  })
);
