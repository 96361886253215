import { axiosGroup } from "./axiosGroup";

export const getLaunchpadsAPI = async () => {
  const result = await axiosGroup.axiosServerAPI.get("/get_list_pre_order");

  return result.data;
};

export const getNFTsAPI = async () => {
  const result = await axiosGroup.axiosServerAPI.get("/get_list_nft");

  return result.data;
};
export const getNFTAPI = async (id: string) => {
  const result = await axiosGroup.axiosServerAPI.get(`/get_nft/${id}`);

  return result.data;
};

export const getTransactionNFTsAPI = async () => {
  const params = {
    module: "logs",
    action: "getLogs",
    fromBlock: 27250244,
    toBlock: 28250244,
    address: "0xd4FfDa0a90ec4e8C70ad94AD36e59bA41E409150",
    topic0: "0x3b2487d95c3524f6721735d5f9085ddf7593099ff0467bbaab3364bbfeaec9d3",
    apikey: process.env.REACT_APP_BSC_MAINNET_API_KEY,

  }
  try {
    const response = await axiosGroup.axiosBSC.get("", { params });
    const { result } = response.data
    const finalResult: any = [];
    const NFTTransferLogs = [];
    for (let i = 0; i < result.length; i++) {
      let start = 2;
      let end = 66;
      const { data } = result[i];
      const NFTTransferValues = [];

      for (let j = 0; j < 10; j++) {
        const value = data.slice(start, end);
        NFTTransferValues.push(value);
        start += 64;
        end += 64;
      }

      const NFTTransferLog = {
        blockNumber: parseInt(result[i].blockNumber, 16),
        timeStamp: parseInt(result[i].timeStamp, 16),
        transactionHash: result[i].transactionHash,
        tokenID: parseInt(NFTTransferValues[0], 16),
        from: "0x" + (NFTTransferValues[1]).slice(24, 65),
        to: "0x" + (NFTTransferValues[2]).slice(24, 65),
        unitPrice: parseInt(NFTTransferValues[3], 16),
        amount: parseInt(NFTTransferValues[4], 16),
        sellerTALAmount: parseInt(NFTTransferValues[5], 16),
        feeTALAmount: parseInt(NFTTransferValues[6], 16),
        feePercent: parseInt(NFTTransferValues[7], 16),
        feePercent2Hand: parseInt(NFTTransferValues[8], 16),
        transferType: parseInt(NFTTransferValues[9], 16),
      }

      if (NFTTransferLog.sellerTALAmount > 0) {
        result[i].NFTTransferLog = NFTTransferLog
        finalResult.push(result[i]);
        NFTTransferLogs.push(NFTTransferLog);
      }
    }
    // console.log('NFTTransferLogs', NFTTransferLogs)
    return finalResult;
  } catch (error) {
    console.log(error)
  }

};

export const approveLaunchpadsAPI = async (preOrderId: string) => {
  const result = await axiosGroup.axiosServerAPI.post("/approve_pre_order", {
    preOrderId,
  });

  return result.data;
};

export const approveNFTsAPI = async (nftStorageId: string) => {
  const result = await axiosGroup.axiosServerAPI.post("/approve_nft", {
    nftStorageId,
    messenger:
      "Your NFT approved by admin. Now you can mint that on the website",
    subject: "Approved nft",
  });

  return result.data;
};

export const cancelLaunchpadsAPI = async (preOrderId: string) => {
  const result = await axiosGroup.axiosServerAPI.post("/cancelled_pre_order", {
    preOrderId,
    messenger:
      "Your NFT rejected by admin. Now you can't mint that on the website",
    subject: "Reject nft",
  });

  return result.data;
};

export const cancelNFTsAPI = async (nftStorageId: string) => {
  const result = await axiosGroup.axiosServerAPI.post("/cancelled_nft", {
    nftStorageId,
  });

  return result.data;
};
