import React from "react";
// Images
import spinner from "../../assets/spinner.gif";
// Styled components
import { StyledContainer } from "./styledComponents";

export const Spinner = () => {
  return (
    <StyledContainer>
      <img src={spinner} alt="Spinner" />
    </StyledContainer>
  );
};
