import { styled } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { StyledProps } from "../../models";

export const StyledDataGrid = styled(DataGrid)(({ theme }: StyledProps) => ({
  height: "fit-content",
  border: "none !important",
  color: '#000',
  background: '#fff',
  borderRadius: "10px",
  "& *": { border: "none !important" },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaders": {
    // border: "3px solid " + theme?.colors.eighth + " !important",
    // borderRadius: "12px 12px 0 0",
    // backgroundColor: `${theme?.colors.seventh}`
  },
  "& .header": {
    fontSize: "16px",
    fontFamily: theme?.fontFamilies.raleway700,
    // background: theme?.colors.seventh,
  },
  "& .MuiDataGrid-row": {
    "& .cell": {
      fontSize: "17px",
      fontFamily: theme?.fontFamilies.raleway400,
      "& > span": {
        fontSize: "14px",
        fontFamily: theme?.fontFamilies.raleway500,
      },
    },
  },
  "& .MuiTablePagination-toolbar, & .MuiDataGrid-footerContainer": {
  },
}));
export const StyledDataGrid01 = styled(DataGrid)(({ theme }: StyledProps) => ({

  minHeight: "500px",
  height: "fit-content",
  border: "5px solid #171832",
  background: theme?.colors.eighth,
  "& *": { border: "none !important" },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: "3px solid " + theme?.colors.eighth + " !important",
    borderRadius: "12px 12px 0 0",
  },
  "& .header": {
    fontSize: "16px",
    fontFamily: theme?.fontFamilies.raleway700,
    background: theme?.colors.seventh,
  },
  "& .MuiDataGrid-row": {
    maxHeight: "inherit !important",
    borderBottom: "1px solid #2F304B !important",

    "& .cell": {
      fontSize: "17px",
      fontFamily: theme?.fontFamilies.raleway700,
      maxHeight: "inherit !important",
      "& > span": {
        fontSize: "14px",
        fontFamily: theme?.fontFamilies.raleway500,
      },
    },
  },
  "& .MuiTablePagination-toolbar, & .MuiDataGrid-footerContainer": {
    background: theme?.colors.seventh,
  },
}));

