// @ts-nocheck

import { networkConfig, TOAST_STATE } from "../const";
import { triggerToast } from "../utils";
import { ethers } from "ethers";

export const getProvider = (
  connectToMetamask: boolean,
  networkName?: string,
  chainId?: string
) => {
  let provider: any;

  switch (true) {
    case connectToMetamask:
      // Connect to the same network with metamask
      provider = detectMetamaskProvider();
      break;
    case chainId === networkConfig[networkName].MAINNET_CHAIN_ID:
      // BSC mainnet
      provider = new ethers.providers.JsonRpcProvider(
        networkConfig[networkName].MAINNET_RPC
      );
      break;
    case chainId === networkConfig[networkName].TESTNET_CHAIN_ID:
      // BSC testnet
      provider = new ethers.providers.JsonRpcProvider(
        networkConfig[networkName].TESTNET_RPC
      );
      break;
    default:
      break;
  }

  return provider;
};

const detectMetamaskProvider = () => {
  let provider;

  if (window.ethereum) provider = window.ethereum;
  else if (window.web3) provider = window.web3.currentProvider;
  else
    triggerToast(
      "No Ethereum browser detected! Check out Metamask",
      TOAST_STATE.Error
    );

  return provider;
};
