import React from "react";
import { Line } from "react-chartjs-2";
import { ITransactionChart } from "./type";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const TransactionChart = ({ data }: ITransactionChart) => {
  const labels = data ? Object?.keys(data) : [];

  const dataChart = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Transations",
        data: data ? Object?.values(data) : [],
        borderColor: "rgb(100, 211, 239)",
        backgroundColor: "rgb(100, 211, 239, 0.2)",
      },
    ],
  };
  return <Line options={options} data={dataChart} />;
};

export default TransactionChart;
