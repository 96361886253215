import { PayloadAction } from "@reduxjs/toolkit";
import { UPDATE_USER_AUTH_STATE } from "./user.type";

type InitialState = {
  isAuthenticated: boolean;
};

const INITIAL_STATE: InitialState = {
  isAuthenticated: false,
};

const userReducer = (state = INITIAL_STATE, action: PayloadAction<any>) => {
  switch (action.type) {
    case UPDATE_USER_AUTH_STATE:
      return updateUser(state, action);
    default:
      return state;
  }
};

const updateUser = (state: InitialState, action: PayloadAction<boolean>) => {
  return {
    ...state,
    isAuthenticated: action.payload,
  };
};

export default userReducer;
