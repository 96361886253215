import React from "react";
import { Bar } from "react-chartjs-2";
import { IRegistationChart } from "./type";

export const RegistationChart = ({ data }: IRegistationChart) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const labels = data ? Object?.keys(data) : [];

  const dataChart = {
    labels,
    datasets: [
      {
        label: "Users",
        data: data ? Object?.values(data) : [],
        backgroundColor: "#64D3EF",
      },
    ],
  };

  return <Bar options={options} data={dataChart as any} />;
};
