import React, { useRef } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "react-redux";
// Const
import { TOAST_STATE } from "../../const";
// Utils
import { triggerToast } from "../../utils";
// Store
import { getContent } from "../../store";

type PrivateProps = {
  props: any;
  img?: string;
};

function DetailsTable({ props, img }: PrivateProps) {
  // useSelector
  const content = useSelector(getContent);

  // useRef
  const walletAdress = useRef(null);

  // Other functions
  const copyToClipboard = (text: any) => {
    console.log(text);
    // navigator.clipboard.writeText(text.current.innerHTML);
  };

  const addFeature = (e: any) => {
    if (e.content.subContent) {
      return (
        <span className="text-[17px] font-montserrat700 text-white lg:col-span-2 col-span-1 xl:col-span-3 flex flex-col xxxl:flex-row justify-between flex-wrap xl:flex-nowrap ">
          <span className="break-all">
            {e.content.text}
            {e.content.subIcon ? (
              <span className="ml-5">{e.content.subIcon}</span>
            ) : (
              ""
            )}
          </span>
          <span className="text-[17px] font-montserrat700 text-white col-span-1 xl:col-span-3">
            <span className="break-all">
              {e.content.subContent.text || e.content.subContent}
              {e.content.subContent.subIcon ? (
                <span className="ml-5">{e.content.subIcon}</span>
              ) : (
                ""
              )}
            </span>
          </span>
        </span>
      );
    } else if (e.content.iCopy) {
      return (
        <span className="text-[17px] font-montserrat700 text-white col-span-1 xxxl:col-span-3 xl:col-span-3 break-all flex">
          <span ref={walletAdress}>
            {e.content.text}
            {e.content.subIcon ? (
              <span className="ml-5">{e.content.subIcon}</span>
            ) : (
              ""
            )}
          </span>
          <span
            className="ml-5 hover:opacity-50 hover:cursor-pointer"
            onClick={() => {
              copyToClipboard(walletAdress);
              triggerToast(content["COPY_TO_CLIPBOARD"], TOAST_STATE.Success);
            }}
          >
            <ContentCopyIcon />
          </span>
        </span>
      );
    }
    return "";
  };
  const CheckContent = (e: any) => {
    if (e.feature) {
      return addFeature(e);
    }
    return (
      <span className="text-[17px] font-montserrat700 text-white col-span-1 xxxl:col-span-3 xl:col-span-3 break-all flex justify-between">
        <span>{e.content}</span>
      </span>
    );
  };
  
  return (
    <div>
      <div className="pt-4">
        <h2 className="font-montserrat600 text-[22px] text-white py-5">
          {props.title}
        </h2>
        <div className="flex flex-col lg:flex-row bg-ninth">
          <div className=" lg:px-[95px] py-10 grid grid-cols-1 xl:grid-cols-4 rounded-md justify-between gap-y-[32px] gap-x-[72px]">
            {props.content.map((e: any, index: number) => (
              <React.Fragment key={index}>
                <span className=" font-raleway500 text-fifth xl:text-end break-words col-span-1 xl:col-span-1">
                  {e.title}
                </span>
                {CheckContent(e)}
              </React.Fragment>
            ))}
          </div>
          {img && (
            <div className="">
              <img src={img} alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DetailsTable;
