import {
  collection,
  DocumentData,
  onSnapshot,
  query,
  QueryConstraint,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { TOAST_STATE } from "../../const";
import { db, secondDb } from "../../firebase";
import { triggerToast } from "../../utils";

export const useGetCollectionFirestore = (
  tableName: string
  //  ,orderBy?: Array<number>
) => {
  const [data, setData] = useState<DocumentData>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!tableName) return;
    setLoading(true);
    onSnapshot(collection(db, tableName), (snapshot) => {
      setData(snapshot.docs.map((doc) => doc?.data()));
      setLoading(false);
    });
  }, [tableName]);

  return { data, loading };
};

export const useGetSingleFirestore = (
  tableName: string,
  where: QueryConstraint
) => {
  const [data, setData] = useState<DocumentData>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const q = query(collection(db, tableName), where);
    setLoading(true);

    onSnapshot(q, (snap) => {
      if (!snap.empty) {
        const result = snap.docs[0].data();
        setData(result);
        setLoading(false);
      } else {
        triggerToast("No documents found with given slug", TOAST_STATE.Error);
        setLoading(false);
      }
    });
  }, [tableName]);

  return { data, loading };
};
export const useGetCollectionFirestoreWithOrderBy = (
  tableName: string,
  orderBy: QueryConstraint
) => {
  const [data, setData] = useState<DocumentData>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const q = query(collection(db, tableName), orderBy);
    setLoading(true);

    onSnapshot(q, (snap) => {
      if (!snap.empty) {
        const result = snap.docs.map((doc) => doc?.data());
        setData(result);
        setLoading(false);
      } else {
        triggerToast("No documents found with given slug", TOAST_STATE.Error);
        setLoading(false);
      }
    });
  }, [tableName, orderBy]);

  return { data, loading };
};
export const useGetCollectionSecondFirestoreWithCondition = (
  tableName: string,
  condition: QueryConstraint
) => {
  const [data, setData] = useState<DocumentData>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const q = query(collection(secondDb, tableName), condition);
    setLoading(true);

    onSnapshot(q, (snap) => {
      if (!snap.empty) {
        const result = snap.docs.map((doc) => doc?.data());
        setData(result);
        setLoading(false);
      } else {
        triggerToast("No documents found with given slug", TOAST_STATE.Error);
        setLoading(false);
      }
    });
  }, [tableName, condition]);

  return { data, loading };
};
