import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { slugs } from "../../const/slugs";

export const DrawerContainer = () => {
  // useState
  const [currentLink, setCurrentLink] = useState<string>("/");

  //useLocation
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.split("/")[1]) {
      setCurrentLink("/");
    } else {
      setCurrentLink(location.pathname.split("/")[1]);
    }
  }, [location?.pathname]);

  // useMemo
  const links = useMemo(
    () => [
      {
        url: slugs.dashboard,
        name: "Dashboard",
        activate: currentLink === "/",
      },
      {
        url: slugs.transactions,
        name: "Transactions",
        activate: currentLink === "transactions",
      },
      {
        url: slugs.users,
        name: "User List",
        activate: currentLink === "users",
      },
      // {
      //   url: slugs.laundpads,
      //   name: "Launchpad",
      //   activate: currentLink === "laundpads",
      // },
      {
        url: slugs.nfts,
        name: "NFT",
        activate: currentLink === "nfts",
      },
      {
        url: slugs.nftViews,
        name: "NFT Views",
        activate: currentLink === slugs.nftViews,
      },
      {
        url: slugs.transactionnft,
        name: "Transaction NFT",
        activate: currentLink === "transactionnft",
      },
      {
        url: slugs.reward,
        name: "Reward NFTs",
        activate: currentLink === "reward",
      },
      {
        url: slugs.createNftManual,
        name: "Create NFTs Manual",
        activate: currentLink === "create-nft-manual",
      },
      // {
      //   url: "",
      //   name: "Locked Token",
      //   activate: currentLink === 3,
      // },
      // {
      //   url: "",
      //   name: "Staking",
      //   activate: currentLink === 4,
      // },
      // {
      //   url: "",
      //   name: "KYC",
      //   activate: currentLink === 5,
      // },
    ],
    [currentLink]
  );

  // // Other functions
  // const handleClickLink = (index: number) => {
  //   // setCurrentLink(index);
  // };

  return (
    <div className="h-full bg-ninth">
      <div className="flex justify-center">
        <img
          src="/resource/images/Logo.png"
          alt="Logo"
          className="mt-[62px] mb-[80px] cursor-pointer"
        />
      </div>
      <ul className="w-full flex flex-col font-Raleway700 text-fifth">
        <li className="py-[15px] pl-9">Menu</li>
        {links.map((link, index) => (
          <li
            key={index}
            className={`py-[15px] pl-9 text-white ${
              link.activate && "activeElemnetDrawer"
            }`}
          >
            <Link to={link.url}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
